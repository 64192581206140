// // Import required libraries
import List from 'list.js'

import css from './styles.css';

const kMinElo = 1100;
const kMaxElo = 2300;

let fbsTeams = [
    {fullname: "Air Force Falcons", shortname: "Air Force"},
    {fullname: "Akron Zips", shortname: "Akron"},
    {fullname: "Alabama Crimson Tide", shortname: "Alabama"},
    {fullname: "UAB Blazers", shortname: "UAB"},
    {fullname: "Appalachian State Mountaineers", shortname: "Appalachian State"},
    {fullname: "Arizona Wildcats", shortname: "Arizona"},
    {fullname: "Arizona State Sun Devils", shortname: "Arizona State"},
    {fullname: "Arkansas Razorbacks", shortname: "Arkansas"},
    {fullname: "Arkansas State Red Wolves", shortname: "Arkansas State"},
    {fullname: "Army Black Knights", shortname: "Army"},
    {fullname: "Auburn Tigers", shortname: "Auburn"},
    {fullname: "Ball State Cardinals", shortname: "Ball State"},
    {fullname: "Baylor Bears", shortname: "Baylor Bears"},
    {fullname: "Boise State Broncos", shortname: "Boise State"},
    {fullname: "Boston College Eagles", shortname: "Boston College"},
    {fullname: "Bowling Green Falcons", shortname: "Bowling Green"},
    {fullname: "Buffalo Bulls", shortname: "Buffalo"},
    {fullname: "BYU Cougars", shortname: "BYU"},
    {fullname: "California Golden Bears", shortname: "Cal"},
    {fullname: "UCLA Bruins", shortname: "UCLA"},
    {fullname: "UCF Knights", shortname: "UCF"},
    {fullname: "Central Michigan Chippewas", shortname: "Central Michigan"},
    {fullname: "Charlotte 49ers", shortname: "Charlotte"},
    {fullname: "Cincinnati Bearcats", shortname: "Cincinnati"},
    {fullname: "Clemson Tigers", shortname: "Clemson"},
    {fullname: "Coastal Carolina Chanticleers", shortname: "Coastal Carolina"},
    {fullname: "Colorado Buffaloes", shortname: "Colorado"},
    {fullname: "Colorado State Rams", shortname: "Colorado State"},
    {fullname: "Connecticut Huskies", shortname: "Connecticut"},
    {fullname: "Duke Blue Devils", shortname: "Duke"},
    {fullname: "Eastern Michigan Eagles", shortname: "Eastern Michigan"},
    {fullname: "East Carolina Pirates", shortname: "East Carolina"},
    {fullname: "FIU Panthers", shortname: "FIU"},
    {fullname: "Florida Gators", shortname: "Florida"},
    {fullname: "Florida Atlantic Owls", shortname: "Florida Atlantic"},
    {fullname: "Florida State Seminoles", shortname: "Florida State"},
    {fullname: "Fresno State Bulldogs", shortname: "Fresno State"},
    {fullname: "Georgia Bulldogs", shortname: "Georgia"},
    {fullname: "Georgia Southern Eagles", shortname: "Georgia Southern"},
    {fullname: "Georgia State Panthers", shortname: "Georgia State"},
    {fullname: "Georgia Tech Yellow Jackets", shortname: "Georgia Tech"},
    {fullname: "Hawai’i Rainbow Warriors", shortname: "Hawai’i"},
    {fullname: "Houston Cougars", shortname: "Houston"},
    {fullname: "Illinois Fighting Illini", shortname: "Illinois"},
    {fullname: "Indiana Hoosiers", shortname: "Indiana"},
    {fullname: "Iowa Hawkeyes", shortname: "Iowa"},
    {fullname: "Iowa State Cyclones", shortname: "Iowa State"},
    {fullname: "Kansas Jayhawks", shortname: "Kansas"},
    {fullname: "Kansas State Wildcats", shortname: "Kansas State"},
    {fullname: "Kent State Golden Flashes", shortname: "Kent State"},
    {fullname: "Kentucky Wilcats", shortname: "Kentucky"},
    {fullname: "LSU Tigers", shortname: "LSU"},
    {fullname: "Louisiana Tech Bulldogs", shortname: "Louisiana Tech"},
    {fullname: "Louisiana-Lafayette Ragin’ Cajuns", shortname: "Louisiana-Lafayette"},
    {fullname: "Louisiana-Monroe Warhawks", shortname: "Louisiana-Monroe"},
    {fullname: "Louisville Cardinals", shortname: "Louisville"},
    {fullname: "Marshall Thundering Herd", shortname: "Marshall"},
    {fullname: "Maryland Terrapins", shortname: "Maryland"},
    {fullname: "Massachusetts Minutemen", shortname: "Massachusetts"},
    {fullname: "Memphis Tigers", shortname: "Memphis"},
    {fullname: "Miami Hurricanes", shortname: "Miami"},
    {fullname: "Miami (OH) RedHawks", shortname: "Miami (OH)"},
    {fullname: "Michigan Wolverines", shortname: "Michigan"},
    {fullname: "Michigan State Spartans", shortname: "Michigan State"},
    {fullname: "Middle Tennessee Blue Raiders", shortname: "Middle Tennessee"},
    {fullname: "Minnesota Golden Gophers", shortname: "Minnesota"},
    {fullname: "Ole Miss Rebels", shortname: "Ole Miss"},
    {fullname: "Mississippi State Bulldogs", shortname: "Mississippi State"},
    {fullname: "Missouri Tigers", shortname: "Missouri"},
    {fullname: "Navy Midshipmen", shortname: "Navy"},
    {fullname: "Nebraska Cornhuskers", shortname: "Nebraska"},
    {fullname: "Nevada Wolf Pack", shortname: "Nevada"},
    {fullname: "UNLV Rebels", shortname: "UNLV"},
    {fullname: "New Mexico Lobos", shortname: "New Mexico"},
    {fullname: "New Mexico State Aggies", shortname: "New Mexico State"},
    {fullname: "North Carolina Tar Heels", shortname: "UNC"},
    {fullname: "NC State Wolfpack", shortname: "NC State"},
    {fullname: "North Texas Mean Green", shortname: "North Texas"},
    {fullname: "NIU Huskies", shortname: "NIU"},
    {fullname: "Northwestern Wildcats", shortname: "Northwestern"},
    {fullname: "Notre Dame Fighting Irish", shortname: "Notre Dame"},
    {fullname: "Ohio Bobcats", shortname: "Ohio"},
    {fullname: "Ohio State Buckeyes", shortname: "Ohio State"},
    {fullname: "Oklahoma Sooners", shortname: "Oklahoma"},
    {fullname: "Oklahoma State Cowboys", shortname: "Oklahoma State"},
    {fullname: "Old Dominion Monarchs", shortname: "Old Dominion"},
    {fullname: "Oregon Ducks", shortname: "Oregon"},
    {fullname: "Oregon State Beavers", shortname: "Oregon State"},
    {fullname: "Penn State Nittany Lions", shortname: "Penn State"},
    {fullname: "Pittsburgh Panthers", shortname: "Pitt"},
    {fullname: "Purdue Boilermakers", shortname: "Purdue"},
    {fullname: "Rice Owls", shortname: "Rice"},
    {fullname: "Rutgers Scarlet Knights", shortname: "Rutgers"},
    {fullname: "San Diego State Aztects", shortname: "San Diego State"},
    {fullname: "San Jose State Spartans", shortname: "San Jose State"},
    {fullname: "South Alabama Jaguars", shortname: "South Alabama"},
    {fullname: "South Carolina Gamecocks", shortname: "South Carolina"},
    {fullname: "South Florida Bulls", shortname: "South Florida"},
    {fullname: "USC Trojans", shortname: "USC"},
    {fullname: "SMU Mustangs", shortname: "SMU"},
    {fullname: "Southern Miss Golden Eagles", shortname: "Southern Miss"},
    {fullname: "Stanford Cardinal", shortname: "Standford"},
    {fullname: "Syracuse Orange", shortname: "Syracuse"},
    {fullname: "TCU Horned Frogs", shortname: "TCU"},
    {fullname: "Temple Owls", shortname: "Temple"},
    {fullname: "Tennessee Volunteers", shortname: "Tennessee"},
    {fullname: "Texas Longhorns", shortname: "Texas"},
    {fullname: "Texas A&M Aggies", shortname: "Texas A&M"},
    {fullname: "Texas State Bobcats", shortname: "Texas State"},
    {fullname: "Texas Tech Red Raiders", shortname: "Texas Tech"},
    {fullname: "UTEP Miners", shortname: "UTEP"},
    {fullname: "UTSA Roadrunners", shortname: "UTSA"},
    {fullname: "Toledo Rockets", shortname: "Toledo"},
    {fullname: "Troy Trojans", shortname: "Troy"},
    {fullname: "Tulane Green Wave", shortname: "Tulane"},
    {fullname: "Tulsa Golden Hurricane", shortname: "Tulsa"},
    {fullname: "Utah Utes", shortname: "Utah"},
    {fullname: "Utah State Aggies", shortname: "Utah State"},
    {fullname: "Vanderbilt Commodores", shortname: "Vanderbilt"},
    {fullname: "Virginia Cavaliers", shortname: "Virginia"},
    {fullname: "Virginia Tech Hokies", shortname: "Virginia Tech"},
    {fullname: "Wake Forest Demon Deacons", shortname: "Wake Forest"},
    {fullname: "Washington Huskies", shortname: "Washington"},
    {fullname: "Washington State Cougars", shortname: "Washington State"},
    {fullname: "West Virginia Mountaineers", shortname: "West Virginia"},
    {fullname: "Western Kentucky Hilltoppers", shortname: "Western Kentucky"},
    {fullname: "Western Michigan Broncos", shortname: "Western Michigan"},
    {fullname: "Wisconsin Badgers", shortname: "Wisconson"},
    {fullname: "Wyoming Cowboys", shortname: "Wyoming"}
];

const kMaxWeek = 16;
let gameDisplayList = null;
let displayWeek = -1;
let currentWeek = -1;
let calendarData, gameData, rankingData, bettingData, teamData, postSeasonBetting, playTypes, bowlData, playsData;

let viewFilterControl = null;
let searchBoxControl = null;
let searchClearButton = null;

// const axios = require('axios');

// call & parse both API endpoints
const urls = [
    // apiEndpoint + "/events?" + apiKey,
    // apiEndpoint + "/odds?regions=us&markets=spreads&oddsFormat=american&bookmakers=draftkings&" + apiKey,


    // "https://w2w.dave-hill.workers.dev/api/data/events/",
    // "https://w2w.dave-hill.workers.dev/api/data/odds/",


    "./eventData.json",
    "./spreadData.json"


]

const cfbApiKey = '/khF67ocBsAsl0aTOO/4xh2p+zpjHp/g3NuVdapNZPbAUUDEjiOhVDFmEfSPJqJF';

const proxyRequestHeaders = new Headers();
proxyRequestHeaders.append("Authorization", `Bearer ${cfbApiKey}`);
proxyRequestHeaders.append("Content-Type", "application/json");

const espnProxyRequestHeaders = new Headers();
proxyRequestHeaders.append("Content-Type", "application/json");

// let cfbApiUrl = 'https://api.collegefootballdata.com/games?year=2024&seasonType=regular&week=5';
// cfbApiUrl = 'https://api.collegefootballdata.com/games?year=2024&week=5&division=fbs';
// cfbApiUrl = 'https://api.collegefootballdata.com/lines?year=2024'; // Betting odds
// // cfbApiUrl = 'https://api.collegefootballdata.com/calendar?year=2024'; // Weekly calendar rnages
// cfbApiUrl = 'https://api.collegefootballdata.com/rankings?year=2024';

function getProxiedRequestPromise(urlString)
{
    const proxyURL = 'https://hidden-sun-6344.dave-hill.workers.dev/corsproxy/?apiurl=';

    let proxiedUrl = proxyURL + urlString;
    let promise = fetch(proxiedUrl, {headers: proxyRequestHeaders}).then(response => {
            if (!response.ok) { // Check for fetch errors
                throw new Error(`Error fetching ${[proxiedUrl]}: ${response.status}`);
            }
            return response.json();
        });

    return promise;
}

function getEspnRequestPromise(urlString)
{
    // const proxyURL = 'https://hidden-sun-6344.dave-hill.workers.dev/corsproxy/?apiurl=';

    // let proxiedUrl = proxyURL + urlString;
    let promise = fetch(urlString, {headers: espnProxyRequestHeaders}).then(response => {
            if (!response.ok) { // Check for fetch errors
                throw new Error(`Error fetching ${[urlString]}: ${response.status}`);
            }
            return response.json();
        });

    return promise;
}

function getLocalFile(urlString)
{
    let promise = fetch(urlString).then(response => {
        if (!response.ok) {
            throw new Error(`Error fetching ${urlString}: ${response.status}`);
        }
        return response.json();
    });

    return promise;
}

function computeNthQuarterScore(scoreline, n)
{
    let result = 0;
    for (let i = 0; i < n; i++)
    {
        result += scoreline[i];
    }
    return result;
}

function computeNthQuarterLeader(awayScores, homeScores, n)
{
    console.assert(1 <= n && n <= homeScores.length);
    let homePoints = 0, awayPoints = 0;
    for (let i = 0; i < n; i++)
    {
        homePoints += homeScores[i];
        awayPoints += awayScores[i];        
    }

    let leader = -1;

    if (homePoints == awayPoints)
        leader = 0;
    else if (homePoints > awayPoints)
        leader = 2;
    else
        leader = 1;
    // console.log("Q" + n + " Leader: " + leader + " ==> " + awayPoints + ", " + homePoints);
    return leader;
}

// Get the calendar
// Figure out the week we're in (through the following Tuesday is the previous week, Wednesday onwards is the new week)
// Get the game data


// cfbApiUrl = apiEndpoint + "/events/?" + apiKey;


// {
//     headers: {
//         'Authorization': `Bearer ${cfbApiKey}`,
//         'Content-Type': 'application/json',
//     }
// };



// // const baseURL = 'https://fantasy.premierleague.com/api/';




// const response = await fetch(apiUrl, { headers });
// const data = await response.json();


// Promise.all(urls.map(url => fetch(url).then(
//     response => {
//         response.json()
//     })))
// .then(
/*
let promises = urls.map(url => fetch(url)
.then(response => {
    if (!response.ok) { // Check for fetch errors
        throw new Error(`Error fetching ${url}: ${response.status}`);
    }
    return response.json(); // Parse JSON
}));

if (true)
{

    console.log(myHeaders);

    let proxiedUrl = proxyURL + cfbApiUrl; //encodeURIComponent(cfbApiUrl);
    promises.push(fetch(proxiedUrl, {headers: myHeaders}).then(response => {
        if (!response.ok) { // Check for fetch errors
            throw new Error(`Error fetching ${[proxiedUrl]}: ${response.status}`);
        }
        return response.json();
    }));
}
*/


let promises = [];

//0
promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/calendar?year=2024')); // get calendar
//1
promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/games?year=2024&seasonType=both&division=fbs'));
//2
promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/rankings?year=2024&seasonType=both'));
//3
promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/lines?year=2024&seasonType=both'));
//4
promises.push(getLocalFile('./teamData.json'));
//5
// promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/lines?year=2024&seasonType=postseason'));


// promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/games?year=2024&seasonType=postseason&division=fbs'));

// 'https://api.collegefootballdata.com/games?year=2024&seasonType=postseason&division=fbs'
// promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/play/types')); //https://api.collegefootballdata.com/plays/types'));

// promises.push(getEspnRequestPromise('http://site.api.espn.com/apis/site/v2/sports/football/college-football/summary?event=401628406'));
// promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/teams/fbs?year=2024'));
// promises.push(getProxiedRequestPromise('https://api.collegefootballdata.com/plays?year=2024&week=6'));


Promise.all(promises)
    .then(results => {



        // console.log('Results:', results);
        // results => {
            calendarData = results[0];
            gameData = results[1];
            rankingData = results[2];
            bettingData = results[3];
            // bowlData = results[4];
            teamData = results[4];

            // postSeasonBetting = results[5];
            // playTypes = results[5];
            // playsData = results[4];
        // const [calendarData, gameData, rankingData, bettingData, playsData] = results; //eventData, spreadData, cfbGames] = results;


        // let postSeasongBets = bettingData.filter((entry) => { 
        //     if (entry.seasonType.localeCompare("postseason") == 0)
        //         return true;
        //     return false;
        // });


        //  Postseason resets to week 1. For now, just move all of these to "week 16"
        bettingData.forEach( (entry) => {
            if (entry.week == 1 && entry.seasonType.localeCompare("postseason") == 0)
            {
                entry.week = kMaxWeek;
            }
        })
        // console.log(JSON.stringify(bowlData));

        //  Postseason resets to week 1. For now, just move all of these to "week 16"
        gameData.forEach( (entry) => {
            if (entry.week == 1 && entry.season_type.localeCompare("postseason") == 0)
            {
                // console.log("rewriting week for playoff game between " + entry.away_team + " @ " + entry.home_team );
                entry.week = kMaxWeek;
            }
        })
       
        // Add bowl games into whatever calendar week they fall in

        // console.log(JSON.stringify(calendarData));
        // console.log(JSON.stringify(gameData));

        // console.log(JSON.stringify(teamData));

        // console.log(JSON.stringify(playTypes));

        let today = new Date(Date.now());

        // walk through weeks until we find the one that starts after today -- we're in the week before that
        for (let i = 0; i < calendarData.length; i++)
        {
            let week = calendarData[i];
            let startDate = new Date(Date.parse(week.firstGameStart));
            startDate.setDate(startDate.getDate()-1);
            // let endDate = new Date(Date.parse(week.lastGameStart));
            // endDate.setDate(endDate.getDate()+1);

            if (startDate > today)
                break;
            
            currentWeek = week.week;
            if (week.seasonType.localeCompare("postseason") == 0)
            {
                currentWeek = kMaxWeek;
            }

            // if (startDate <= today && today <= endDate)
            // {
            //     currentWeek = week.week;
            //     break;
            // }
        }

        console.log(
            "week number = " + currentWeek
        );

        // currentWeek = 6;


        // updateListForWeek(currentWeek);
        displayWeek = currentWeek;
        onChangeWeek(0);


        

        // document.getElementById("rankbuttonid")

        // for (let value of values)
        // {
        //     hackerList.add(value)
        // }

        /*const gameTable = document.createElement('table');
        gameTable.innerHTML = `
        <tr>
            <th style="width:40%">Game</th>
            <th style="width:20%">Time</th>
            <th>Spread</th>
        </tr>`;
        gameListDiv.append(gameTable);

        for (game of games)
        {
            // break;
            // date.toLocaleString('default', { month: 'long' }) + " " + date.getDate().toString() + " " + date.getHours() + ":" + date.getMinutes();

            let dateString = new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(game.date);
            const gameElement = document.createElement('tr');
            // gameElement.className = game.spread < 10 ? "tr_good" : "tr";
            let gameString = 
                ((game.away_rank > 25) ? '' : game.away_rank.toString() + ' ') + game.away_team + " @ " + 
                ((game.home_rank > 25) ? '' : game.home_rank.toString() + ' ') + game.home_team;

            // console.log(gameString);

            gameElement.innerHTML = `
            <td>${gameString}</td>
            <td>${dateString}</td>
            <td>${game.spread}</td>
            `;
            gameTable.appendChild(gameElement);   
        }
        */

            // create HTML
            
            // console.log(entry);
    })
    .catch(error => console.error('Error:', error));




    if (false)
    {
        document.getElementById("firebuttonid").onclick = () => {
            gameDisplayList.search("\u{1F525}")
        }

        document.getElementById("rankbuttonid").onclick = () => {

            gameDisplayList.search( 'xxx', () => {
            for (var k = 0, kl = gameDisplayList.items.length; k < kl; k++) {
                let item = gameDisplayList.items[k]
                item.found = false;
                let gameitem = item.values().gameitem;
                if (gameitem.away_rank <= 25 || gameitem.home_rank <= 25)
                    item.found = true;
                // Insert your custom search logic here, set found = true
        
            }});
        }

        document.getElementById("futurebuttonid").onclick = () => {

            gameDisplayList.search( 'xxx', () => {
            for (var k = 0, kl = gameDisplayList.items.length; k < kl; k++) {
                let item = gameDisplayList.items[k]
                item.found = false;
                let gameitem = item.values().gameitem;
                if (!gameitem.completed && !gameitem.skip)
                    item.found = true;
                // Insert your custom search logic here, set found = true
        
            }});
        }

        document.getElementById("allbuttonid").onclick = () => {
            gameDisplayList.search('');
        }
        // document.onload = () => {
            document.getElementById('prevweekbuttonid').onclick = () => {onChangeWeek( -1 )};
            document.getElementById('nextweekbuttonid').onclick = () => {onChangeWeek( 1 )};
        // }
    }
    else
    {
        if (viewFilterControl == null)
            viewFilterControl = document.getElementById("view_filter");

        viewFilterControl.onchange = (_event) => {
            // console.log(_item);
            viewFilterChanged();
        };

        document.getElementById('prevweekbuttonid').onclick = () => {onChangeWeek( -1 )};
        document.getElementById('nextweekbuttonid').onclick = () => {onChangeWeek( 1 )};

        searchBoxControl = document.getElementById('search_box');
        searchClearButton = document.getElementById('clear_search');

        searchClearButton.onclick = () => {
            searchBoxControl.value = '';
            gameDisplayList.search('');
            searchClearButton.classList.toggle('show', false);
        }

        searchBoxControl.addEventListener('input', () => {
            searchClearButton.classList.toggle('show', searchBoxControl.value.trim() !== '');
        });
    }
    


// function firebutton()
// {
//     let textfield = getElementById("search_box");
//     textfield.setAttribute('value', "\u{1F525}");
// }

function onChangeWeek(increment)
{
    displayWeek = displayWeek + increment;
    let prevButton = document.getElementById('prevweekbuttonid');
    let nextButton = document.getElementById('nextweekbuttonid');

    if (displayWeek <= 1)
    {
        displayWeek = 1;
        prevButton.disabled = true;
    }
    else if (displayWeek >= kMaxWeek)
    {
        displayWeek = kMaxWeek;
        nextButton.disabled = true;
    }
    else
    {
        nextButton.disabled = false;
        prevButton.disabled = false;
    }

    document.getElementById('weeklabelid').innerHTML = "Week " + displayWeek;
    updateListForWeek(displayWeek);

    viewFilterChanged(); // re-apply the view filter based on the current value
}

function probabilityOfWinning(_moneyline)
{
    if (_moneyline < 0)
    {
        return _moneyline / (_moneyline - 100);
    }
    else
    {
        return 100 / (_moneyline + 100);
    }
}

function clamp(_value, _min, _max)
{
    return Math.min(Math.max(_min, _value), _max);
}

function updateListForWeek(_week)
{
    displayWeek = _week;

    let filteredGames = gameData.filter((game) => (game.week == displayWeek && (game.away_division === "fbs" || game.home_division === "fbs")));

    let filteredRanking = rankingData.find((entry) => (entry.week == displayWeek));
    // let filteredPoll = rankingData.filter((entry) => (entry.week == currentWeek));
    let filteredBettingData = bettingData.filter((entry) => (entry.week == displayWeek));

    // console.log(JSON.stringify(cfbGames)); //.toString());

    // filter data to the week and conference we want
    // let cfbGamesFiltered = cfbGames.filter((game) => (game.week == 5 && (game.away_division === "fbs" || game.home_division === "fbs")));




    const gameListDiv = document.getElementById('game-list');

    let games = [];
    let rankingPoll = null;
    if (filteredRanking && filteredRanking.polls)
    {
        rankingPoll = filteredRanking.polls.find((entry) => (entry.poll == "Playoff Committee Rankings"));
        if (!rankingPoll)
        {
            rankingPoll = filteredRanking.polls.find((entry) => (entry.poll == "AP Top 25"));
            console.assert(rankingPoll);
            console.log("Using AP Poll Rankings");
        }
        else
        {
            console.log("Using CFB Rankings");
        }
    }

    filteredGames.sort((a,b) => {
        return a.date - b.date;
    });

    let game;
    for (game of filteredGames) {

        let date = new Date(Date.parse(game.start_date));
        let gameEntry = {
            date: date,
            home_team: game.home_team,
            away_team: game.away_team,

        };

        if (rankingPoll)
        {
            gameEntry.home_rank = rankingPoll.ranks.find((entry) => (entry.school == game.home_team));
            if (gameEntry.home_rank != null)
                gameEntry.home_rank = gameEntry.home_rank.rank;
            else
                gameEntry.home_rank = 1000;

            gameEntry.away_rank = rankingPoll.ranks.find((entry) => (entry.school == game.away_team));
            if (gameEntry.away_rank != null)
                gameEntry.away_rank = gameEntry.away_rank.rank;
            else
                gameEntry.away_rank = 1000;
            
        }
        else
        {
            gameEntry.home_rank = 1000;
            gameEntry.away_rank = 1000;
        }






        let watchability;
        
        let skip = true;
        if (game.completed)
        {
            let pointDifferential = Math.abs(game.home_points - game.away_points);
            let totalPoints = game.home_points + game.away_points;
            let endOfRegulationTotalPoints = computeNthQuarterScore(game.home_line_scores, 4) + computeNthQuarterScore(game.away_line_scores, 4);

            let watchForPoints = pointDifferential < 17; // two score game

            let excitementIndex = game.excitement_index ?? 0.0;

            // let watchForExcitementIndex = excitementIndex > ;
            
            // Check for mayhem
            let homeWin = game.home_points > game.away_points;
            let winnerRank = homeWin ? gameEntry.home_rank : gameEntry.away_rank; 
            let loserRank = homeWin ? gameEntry.away_rank : gameEntry.home_rank;

            let winnerScore = homeWin ? game.home_points : game.away_points;
            let loserScore = homeWin ? game.away_points : game.home_points;
            
            let watchForMayhem = winnerRank > loserRank; // lower rank number == higher number, so if loser rank is greater than winner, it's mayhem

            let watchForOvertime = game.home_line_scores.length > 4;

            let watchFor4thQuarterComeback = false;
            let fourthQuarterComebackGap = 0;
            if (!watchForOvertime)
            {
                // check for a 4th quarter comeback
                let home3rdQuarterScore = computeNthQuarterScore(game.home_line_scores, 3);
                let away3rdQuarterScore = computeNthQuarterScore(game.away_line_scores, 3);
                let winnerThirdQuarterScore = homeWin ? home3rdQuarterScore : away3rdQuarterScore;
                let loserThirdQuarterScore = homeWin ? away3rdQuarterScore : home3rdQuarterScore;

                watchFor4thQuarterComeback = loserThirdQuarterScore > winnerThirdQuarterScore;

                if (watchFor4thQuarterComeback)
                {
                    fourthQuarterComebackGap = (homeWin ? game.home_points : game.away_points) - winnerThirdQuarterScore;

                    // 4th quarter comeback gap should be "how much were we losing by at end of 3rd" vs. "how much were we winning by at end of 4th"
                    // Or, how much were we down by at end of third

                    fourthQuarterComebackGap = (loserThirdQuarterScore - winnerThirdQuarterScore) + (winnerScore - loserScore);
                }
            }
                
            

            if (false)
            {
                if (
                    (endOfRegulationTotalPoints > 40 && watchForOvertime) //high-scoring + overtime
                    || (watchForMayhem && (watchForOvertime || pointDifferential < 17))
                    // || (!watchForOvertime && endOfRegulationTotalPoints > 40 && excitementIndex > 7) // exciting, high scoring game
                    || (pointDifferential < 4 && totalPoints > 60)) // close, high-scoring game
                {
                    watchability = "\u{1F525}\u{1F525}\u{1F525}";
                    skip = false;
                }
                else if ((watchFor4thQuarterComeback && fourthQuarterComebackGap > 16) || (pointDifferential < 10 && totalPoints > 60))
                {
                    watchability = "\u{1F525}\u{1F525}";
                    skip = false;
                }
                else if ((watchForPoints || excitementIndex > 6.5 || watchForMayhem || watchForOvertime || watchFor4thQuarterComeback))
                {
                    watchability = "\u{1F525}";
                    skip = false;
                }
                // watchability = (watchForPoints || watchForExcitementIndex || watchForMayhem 
                //     || watchForOvertime || watchFor4thQuarterComeback) ? "\u{1F525}" : "Skip";
            }
            else if (pointDifferential < 17) // delta is max of two-score game... otherwise skip
            {
                let watchabilityScore = 0.0;

                let pointDifferentialScore = 1.0 - Math.min(pointDifferential / 16, 1.0); // smaller point differential yields higher score
                pointDifferentialScore *= pointDifferentialScore; // squaring a 0..1 number makes numbers closer to one (i.e., smaller differential) get rated higher
                let overtimeScore = watchForOvertime ? 1 : 0;
                let fourthQuarterComebackScore = watchFor4thQuarterComeback ? Math.min(fourthQuarterComebackGap / 21, 1.0) : 0;
                let lotsOfScoringScore = Math.min(endOfRegulationTotalPoints / 70.0, 1.0);
                let mayhemScore = watchForMayhem ? (0.25 + 0.75 * Math.min((winnerRank - loserRank) / 25, 1.0)) : 0;
                let top10MayhemScore = (loserRank) <= 10 ? 0.5 : 0;
                let top5MayhemScore = (loserRank) <= 5 ? 0.5 : 0;

                let perQuarterExcitementScores = 0.0;
                
                // compute how exciting each quarter is (i.e., how much scoring)
                for (let i = 0; i < 4; i++) // omit overtime
                {
                    let pointsInQuarter = game.home_line_scores[i] + game.away_line_scores [i];
                    if (pointsInQuarter > 9) // if there's some minimum threshold of scoring in the quarter, we give it some weight
                    {
                        perQuarterExcitementScores += Math.min( pointsInQuarter / 28, 1.0) * 0.25;
                    }
                }

                // compute how many lead changes
                let perQuarterLeadChanges = 0;
                let leader = computeNthQuarterLeader(game.away_line_scores, game.home_line_scores, 1);
                // console.log("Leader Q1: " + leader);
                let leadChanges = 0;
                for (let i = 2; i <= game.away_line_scores.length; i++)
                {
                    let newLeader = computeNthQuarterLeader(game.away_line_scores, game.home_line_scores, i);
                    // console.log("Leader Q" + i + ": " + leader);

                    if (newLeader != leader)
                    {
                        leadChanges++;
                        leader = newLeader;
                    }
                }
                // console.log("Lead Changes: " + leadChanges);

                // compute how close the game is throughout

                let closeThroughoutScore = 0.0;
                for (let i = 1; i <= 4; i++)
                {
                    let perQuarterDiff = Math.abs(computeNthQuarterScore(game.home_line_scores, i) - computeNthQuarterScore(game.away_line_scores, i));
                    closeThroughoutScore += 0.25 * Math.max(1.0 - perQuarterDiff / 16, 0.0);
                }


                watchabilityScore = 
                    (mayhemScore + top10MayhemScore + top5MayhemScore) * 1.25 +
                    overtimeScore * 1.0 + 
                    pointDifferentialScore * 1.0 +
                    closeThroughoutScore * 0.85 + 
                    fourthQuarterComebackScore * 0.75 +
                    perQuarterExcitementScores * 0.5 +
                    lotsOfScoringScore * 0.5 + 
                    (leadChanges / 4) * 0.5 ;

                // stack rank
                // mayhem **
                // overtime **
                // close game **
                // close throughout 0.5
                // 4th quarter comeback 0.5
                // scoring throughout the game 0.5
                // lots of scoring 0.5
                // lead changes 0.5
                
                let fires = Math.ceil(clamp(watchabilityScore * 1.5 * (3/5), 1, 3)); //Math.ceil((watchabilityScore / 5) * 3); // 1, 2, or 3

                skip = false;
                watchability = "";
                for (let i = 0; i < fires; i++)
                {
                    watchability += "\u{1F525}";
                    // if ((fires == 4 && i == 1) || (fires == 5 && i == 2))
                    //     watchability += "<br class='br_mobile_only'>";
                }
                // watchability += " " + watchabilityScore.toFixed(1).toString();
                // console.log(
                //     game.away_team + " vs " + game.home_team + "\n" +
                //     "point diff: " + pointDifferentialScore.toFixed(2).toString() + "\n" + 
                //     "overtime: " + overtimeScore.toString() + "\n" + 
                //     "4th Quarter comeback: " + fourthQuarterComebackScore.toFixed(2).toString() + "\n" + 
                //     "Scoring: " + lotsOfScoringScore.toFixed(2).toString() + "\n" + 
                //     "Per Quarter: " + perQuarterExcitementScores.toFixed(2).toString() + "\n" + 
                //     "Lead Changes: " + (leadChanges / 4).toFixed(2).toString() + "\n" + 
                //     "Mayhem: " + mayhemScore.toFixed(2).toString()
                // );

            }
            else
            {
                // watchability = "Skip";
                watchability = "\u{274C}";
            }

                
            // console.log(game.away_team + ": " + game.away_points + " @ " + game.home_team + ": " + game.home_points );
        }
        else if (game.home_line_scores && game.home_away_scores )
        {
            console.table(game);
            watchability = "In Progress";
            skip = false;
        }
        else
        {

            console.log("In Progress: " + game.id + ": " + game.away_team + " vs. " + game.home_team);
            let gameBettingData = filteredBettingData.find((entry) => (entry.homeTeam == game.home_team));
            let spread = null;
            if (gameBettingData && gameBettingData.lines && gameBettingData.lines.length > 0)
            {
                spread = Math.abs(gameBettingData.lines[0].spread);
            }
            skip = (spread && spread >= 15);
            watchability = "No Data";
            if (skip)
            {
                watchability = "Skip";
            }
            else if (spread)
            {
                let competitiveness = 1 - (spread / 15);

                let elo1 = clamp(game.away_pregame_elo, kMinElo, kMaxElo);
                let elo2 = clamp(game.home_pregame_elo, kMinElo, kMaxElo);
                let teamStrength = (elo1 + elo2) / 2;
                teamStrength = (teamStrength - kMinElo) / (kMaxElo - kMinElo);

                let favoriteOdds = Math.min(gameBettingData.lines[0].homeMoneyline, gameBettingData.lines[0].awayMoneyline);
                let favoriteProbOfWinning = probabilityOfWinning(favoriteOdds); // even odds is 50-50, and goes up from there
                console.assert(favoriteProbOfWinning >= 0.5);
                let uncertainty = 1.0 - 2.0 * (favoriteProbOfWinning - 0.5); // convert to a 0..1 value

                let highScoring = clamp((gameBettingData.lines[0].overUnder - 10) / (70 - 10), 0, 1);
                

                let highStakesMultiplier = 1.0;
                if (game.conference_game)
                {
                    highStakesMultiplier += 0.1;
                }
                if (game.away_rank < 25 && game.home_rank < 25)
                {
                    highStakesMultiplier += 0.1;
                }

                let watchScore = (competitiveness + teamStrength + uncertainty + highScoring) * highStakesMultiplier;

                let balls = Math.ceil( clamp(watchScore * 1.5 * (3/5), 1, 3));

                watchability = "";
                for (let i = 0; i < balls; i++)
                {
                    watchability += "\u{1F52E}";
                    if ((balls == 4 && i == 1) || (balls == 5 && i == 2))
                        watchability += "<br class='br_mobile_only'>";
                }

                // if (watchScore > 3.25)
                // {
                //     watchability = "\u{1F52E}\u{1F52E}\u{1F52E}";
                // }
                // else if (watchScore > 2.4)
                // {
                //     watchability = "\u{1F52E}\u{1F52E}";
                // }
                // else
                // {
                //     watchability = "\u{1F52E}";
                // }
            }
        }
        // get ranking

            // let dateString = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(date);

        gameEntry['spread'] = watchability;// ? "watch" : "skip";
        gameEntry['completed'] = game.completed;
        gameEntry['skip'] = skip;
        gameEntry['away_id'] = game.away_id;
        gameEntry['home_id'] = game.home_id;

        if (game.notes && game.notes.length > 0)
        {
            if (game.notes.indexOf("College Football Playoff") >= 0 ||
                game.notes.indexOf("Championship") >= 0)
            {
                gameEntry['bowl_title'] = "\u{1F3C6} <i>" + game.notes + "</i>";
            }
            else
            {
                gameEntry['bowl_title'] = "<i>" + game.notes + "</i>";
            }
        }
        else
            gameEntry['bowl_title'] = '';

        games.push(gameEntry);
    }

    // sort entries
    games.sort((a,b) => {
        
        if (a.date.getTime() === b.date.getTime() )
        {
            let aRankedOnRanked = a.away_rank < 26 && a.home_rank < 26;
            let bRankedOnRanked = b.away_rank < 26 && b.home_rank < 26;
            let aOneRanked = a.away_rank < 26 || a.home_rank < 26;
            let bOneRanked = b.away_rank < 26 || b.home_rank < 26;

            let aMaxRank = Math.min(a.away_rank, a.home_rank);
            let bMaxRank = Math.min(b.away_rank, b.home_rank);

            if (aRankedOnRanked != bRankedOnRanked)
            {
                return aRankedOnRanked ? -1 : 1;
            }
            if (aOneRanked != bOneRanked)
            {
                return aOneRanked ? -1 : 1;
            }

            return a.away_team.localeCompare(b.away_team); // < b.away_team;
            // if (a.away_rank < 25 && a.home_rank < 25)
            // let val = a.spread - b.spread;
            // return val;
        }
        return a.date - b.date;
    });

        // games = games.sort();
        // shuffleArray(games);
      
    

    var options = {
        // template: '<h2>{{category}}</h2><ul>{{#items}}<li>{{name}}</li>{{/items}}</ul>',
        item: 'game-list-item',
        // item: function(item) {
        //     let element = document.getElementById('game-list-item');
        //     return element.cloneNode(true);
        // },
        valueNames: [
            'bowl_game_title',
            {name:'away_team_logo', attr:'src'},
            'away_team_name', 
            'away_team_shortname', 
            {name:'home_team_logo', attr: 'src'}, 
            'home_team_name', 
            'home_team_shortname', 
            'time', 
            'watchability'
        ]
    };
    
    var values = [];
    
    // for (let i = 0; i < games.length; i++)
    values.push
    for (game of games)
    {
        // let game = games[i];

        let dateString = new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(game.date);
        // const gameElement = document.createElement('li');

        let gameString = 
            ((game.away_rank > 25) ? '' : game.away_rank.toString() + ' ') + game.away_team + " @ " + 
            ((game.home_rank > 25) ? '' : game.home_rank.toString() + ' ') + game.home_team;

        // game.away_team = "Air Force";
        let away_team = teamData.find((entry) => (entry.id == game.away_id));
        let home_team = teamData.find((entry) => (entry.id == game.home_id));

            // let result = entry.school.localeCompare(game.away_team);
        //     // return result == 0});
        // let home_team = teamData.find((entry) => {
        //     entry.school.localeCompare(game.home_team) == 0});

        let item = {
            bowl_game_title: game.bowl_title,
            away_team_logo: ("https://a.espncdn.com/i/teamlogos/ncaa/500/" + game.away_id.toString() + ".png"), 
            away_team_name: ((game.away_rank > 25) ? '' : game.away_rank.toString() + ' ') + game.away_team + (away_team ? " " + away_team.mascot : ""),
            away_team_shortname: ((game.away_rank > 25) ? '' : game.away_rank.toString() + ' ') + game.away_team,
            home_team_logo: ("https://a.espncdn.com/i/teamlogos/ncaa/500/" + game.home_id.toString() + ".png"),
            home_team_name: ((game.home_rank > 25) ? '' : game.home_rank.toString() + ' ') + game.home_team + (home_team ? " " + home_team.mascot : ""),
            home_team_shortname: ((game.home_rank > 25) ? '' : game.home_rank.toString() + ' ') + game.home_team,
            // game: gameString,
            time: dateString,
            watchability: game.spread,
            gameitem: game,
            // mascot: (away_team ? away_team.mascot : "") + ", " + (home_team ? home_team.mascot : ""),
        };
        values.push(item);
        
    }


    if (gameDisplayList != null)
    {
        gameDisplayList.clear();
        gameDisplayList.add(values);
        gameDisplayList.search(''); //reset filer @TODO - persist/repapply filter?
    }
    else
    {
        gameDisplayList = new List('game-list-div', options);
        
        gameDisplayList.add(values);
    }
}

function viewFilterChanged()
{
    console.log(viewFilterControl.value);

    if (viewFilterControl.value == 'view_all_good_games')
    {
        gameDisplayList.search( 'xxx', () => {
            for (var k = 0, kl = gameDisplayList.items.length; k < kl; k++) {
                let item = gameDisplayList.items[k]
                item.found = false;
                let gameitem = item.values().gameitem;
                if (!gameitem.skip)
                    item.found = true;
                // Insert your custom search logic here, set found = true
        
            }});
    }
    else if (viewFilterControl.value == 'view_ranked_games')
    {
        gameDisplayList.search( 'xxx', () => {
            for (var k = 0, kl = gameDisplayList.items.length; k < kl; k++) {
                let item = gameDisplayList.items[k]
                item.found = false;
                let gameitem = item.values().gameitem;
                if (gameitem.away_rank <= 25 || gameitem.home_rank <= 25)
                    item.found = true;
                // Insert your custom search logic here, set found = true
        
            }});
    }
    else if (viewFilterChanged.value == 'view_todays_games')
    {
        // gameDisplayList.search( 'xxx', () => {
        //     for (var k = 0, kl = gameDisplayList.items.length; k < kl; k++) {
        //         let item = gameDisplayList.items[k]
        //         item.found = false;
        //         let gameitem = item.values().gameitem;
        //         if (gameitem.away_rank <= 25 || gameitem.home_rank <= 25)
        //             item.found = true;
        //         // Insert your custom search logic here, set found = true
        
        //     }});
    }
    else
    {
        gameDisplayList.search('');
    }

}